.phones {
    z-index: 5;
    opacity: 1;
    transition: all .5s;
}

.phones.phones-wrapper-gone {
    opacity: 0;
}

.phones video {
    object-fit: cover;
}

.phones p {
    position: absolute;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    color: #FFFFFF;
    white-space: pre;
    transition: all .5s;
}

.phones-text-1 {
    top: 68.1%;
    left: 5.31%;

    font-size: min(4.17vh, 2.34vw); /*45px*/
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(1, 3, 16, 0.5);
}

.phones-text-1.phones-text-1-initial {
    left: -44.96%;
}


.phones-text-2 {
    top: 83.33%;
    left: 5.31%;

    font-size: min(2.31vh, 1.3vw); /*25px*/
    font-weight: 500;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.45);
}

.phones-text-2.phones-text-2-initial {
    left: -44.96%;
}

.phones-text-3 {
    top: 10.65%;
    left: 64.84%;
    width: 31.04%;

    font-size: min(6.48vh, 3.65vw); /*70px*/
    font-weight: 500;
    text-align: center;

    opacity: 1;
}

.phones-text-3.phones-text-3-initial {
    opacity: 0;
}

.phones-text-4 {
    left: 64.84%;
    top: 23.15%;

    font-size: min(2.31vh, 1.3vw); /*25px*/
    font-weight: 500;

    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.45);
}

.phones-text-4.phones-text-4-initial {
    left: 114.84%;
}
