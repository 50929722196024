.tokenomics {
    position: relative;
    width: 100%;
    color: #fff;
    margin-bottom: 50%;
    overflow: hidden;
}

.tokenomics-wrapper {
    width: 100%;
    max-width: 1600px;
    margin: auto;
}

.tokenomics-preserver {
    position: relative;
    width: 100%;
    overflow: visible;
    padding-top: 120%;
}

.tokenomics-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: auto;
}

.tokenomics-header {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10%;
    left: 0;
    right: 0;
    transition: top 2s;
}

.tokenomics-header-text {
    position: absolute;
    top: 1%;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(18.52vh, 10.42vw); /*200px*/
    text-transform: uppercase;
    margin: 0;

    color: #FFFFFF;
    z-index: 2;

    mix-blend-mode: soft-light;
    opacity: 0.4;
}

.tokenomics-header-glow {
    position: absolute;
    top: -0.2%;
    width: 120%;
    left: -7%;
    z-index: 1;
    opacity: 0;
    transition: opacity 2s;
}

.pie-wrapper {
    position: absolute;
    left: 13%;
    top: 12%;
    width: 40%;
    height: 0;
    z-index: 3;
    overflow: visible;
}

.pie-preserver {
    position: relative;
    padding-top: 100%;
}

.pie-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.pie-piece {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: all .5s;
}

.pie-piece.pie-piece-clicked {
    transform: scale(1.25);
}

.pie-button {
    position: absolute;
    /* background: #00000050; */
    z-index: 4;
}

.pie-button-train {
    top: -10%;
    left: 48%;
    right: -10%;
    bottom: 32%;
}

.pie-button-liquidity {
    top: 68%;
    left: 51%;
    right: -4%;
    bottom: -9%;
}

.pie-button-marketing {
    top: 82%;
    left: 7%;
    right: 49%;
    bottom: -9%;
}

.pie-button-metaverse {
    top: 54%;
    left: -9%;
    right: 79%;
    bottom: 18%;
}

.pie-button-public {
    top: 26%;
    left: -9%;
    right: 84%;
    bottom: 46%;
}

.pie-button-team {
    top: -5%;
    left: -1%;
    right: 70%;
    bottom: 74%;
}

.pie-button-advisors {
    top: -10%;
    left: 30%;
    right: 59%;
    bottom: 86%;
}

.pie-button-private {
    top: -10%;
    left: 41%;
    right: 52%;
    bottom: 87%;
}

.pie-shadow {
    position: absolute;
    width: 83%;
    height: 83%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin: auto;
}

.pie-token {
    position: absolute;
    width: 60%;
    height: 60%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    margin: auto;
}

.tokenomics-legend {
    position: absolute;
    top: 17%;
    left: 60%;
    width: 40%;
    height: 30%;
    z-index: 3;
}

.tokenomics-legend-row {
    position: relative;
    height: 9%;
    width: 100%;
}

.tokenomics-legend-dot {
    position: absolute;
    left: 5%;
    top: 50%;
    height: 60%;
    transform: translate(-50%,-50%);
}

.pie-piece-clicked .tokenomics-legend-dot{
    height: 75%;
}

.tokenomics-legend-text {
    position: absolute;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #3B667A;

    left: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.pie-piece-clicked .tokenomics-legend-text {
    text-shadow: 0px 4px 4px #000000;
}

.pie-piece-clicked .tokenomics-legend-text-train{
    color: #853FFF;
}

.pie-piece-clicked .tokenomics-legend-text-liquidity {
    color: #591DDD;
}

.pie-piece-clicked .tokenomics-legend-text-marketing {
    color: #4795EE;
}

.pie-piece-clicked .tokenomics-legend-text-metaverse {
    color: #00D0F9;
}

.pie-piece-clicked .tokenomics-legend-text-public {
    color: #00FFF7;
}

.pie-piece-clicked .tokenomics-legend-text-team {
    color: #BD7BFF;
}

.pie-piece-clicked .tokenomics-legend-text-advisors {
    color: #FF1AE8;
}

.pie-piece-clicked .tokenomics-legend-text-private {
    color: #FFFFFF;
}

.tokenomics-node {
    position: absolute;
    width: 20%;
    height: 18%;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    padding-top: 7em;
    transition: all .6s;
}

.tokenomics-node-icon {
    position: absolute;
    left: 50%;
    right: 10%;
    width: 20%;
    top: 15%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity .6s;
}

.tokenomics-node-text {
    position: absolute;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    line-height: 1.15;
    white-space: pre;
    min-height: 2em;
    top: 1000%;
    padding-left: 10%;
    color: #FFFFFF;
    margin: 0 0 5%;
    transition: all .7s;
}

.tokenomics-node-arrowup {
    top: 57%;
    left: 13%;
}
.tokenomics-node-arrowup .tokenomics-node-icon {width:18%;}

.tokenomics-node-flame {
    top: 57%;
    left: 38.5%;
}

.tokenomics-node-money {
    top: 57%;
    left: 64%;
}
.tokenomics-node-money .tokenomics-node-icon {width:25%;}

.tokenomics-node-key {
    top: 73%;
    left: 13%;
}
.tokenomics-node-key .tokenomics-node-icon {width:30%;}

.tokenomics-node-key .tokenomics-node-text {
    font-size: min(3.24vh,1.82vw); /*35px*/
    text-transform: uppercase;
    color: #4973FC;
}

.tokenomics-node-recovery {
    top: 73%;
    left: 38.5%;
}

.tokenomics-node-arrowright {
    top: 73%;
    left: 64%;
}
.tokenomics-node-arrowright .tokenomics-node-icon {width:25%;}

.tokenomics-part {
    position: absolute;
    height: 50%;
    width: .1%;
    left: 0;
    z-index: 10;
    pointer-events: none;
}

.tokenomics-part1 {
    top: 0;
}

.tokenomics-part2 {
    top: 50%;
}
