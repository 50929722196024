.howtostart {

}

.howtostart-content {
    z-index: 2;
}

.howtostart-background {
    position: absolute;
    width: 100%;
    left: 0.21%;
    height: 339.07%;
    top: -183.98%;
    transition: all .5s;
    mix-blend-mode: soft-light;
    pointer-events: none;
    z-index: 1;
}

.howtostart-background.howtostart-background-initial {
    top: -148.89%;
}

.howtostart-header {
    position: absolute;
    width: 100%;
    left: 0%;
    top: 13.06%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(9.26vh, 5.21vw); /*100px*/
    text-align: center;
    letter-spacing: 0.025em;
    text-transform: uppercase;

    color: #FFFFFF;
    transition: all .5s;

    text-shadow: 0px 0px 5px #FFFFFF;
}


.howtostart-header.howtostart-header-initial0 {
    top: 85%;
}

.howtostart-header.howtostart-header-initial1 {
    top: 27.87%;
}

.howtostart-rect {
    position: absolute;

    background: #FFFFFF;
    box-shadow: 0px 0px 7px #FFFFFF;
    font-size: min(5.93vh, 3.33vw); /*64px*/
    border-radius: 1em;
    transition: all .5s;
}

.howtostart-rect1 {
    opacity: 0;
}

.howtostart-rect1.howtostart-rect1-initial0 {
    top: -20%;
    width: 0.36%;
    height: 100%;
    left: 50.2%;
    opacity: 1;
}

.howtostart-rect1.howtostart-rect1-initial1 {
    top: 44.1%;
    width: 0.42%;
    height: 8.19%;
    left: 49.8%;
    opacity: 1;
}

.howtostart-rect1.howtostart-rect1-initial2 {
    top: 46.39%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
    opacity: 1;
}

.howtostart-rect1.howtostart-rect1-initial3b {
    top: 46.39%;
    width: 0.42%;
    height: 5.56%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect1.howtostart-rect1-initial4 {
    top: 45%;
    width: 0.42%;
    height: 8.06%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect1.howtostart-rect1-initial5 {
    top: 146.39%;
    width: 0.42%;
    height: 8.06%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect2 {
    opacity: 0;
}

.howtostart-rect2.howtostart-rect2-initial0 {
    top: 46.39%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
}

.howtostart-rect2.howtostart-rect2-initial1 {
    top: 46.39%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
    opacity: 1;
}

.howtostart-rect2.howtostart-rect2-initial2 {
    top: 53.8%;
    width: 0.42%;
    height: 7.41%;
    left: 49.8%;
    opacity: 1;
}

.howtostart-rect2.howtostart-rect2-initial3 {
    top: 53.8%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
    opacity: 1;
}


.howtostart-rect2.howtostart-rect2-initial3b {
    top: 53.8%;
    width: 0.42%;
    height: 5.56%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect2.howtostart-rect2-initial4 {
    top: 52.8%;
    width: 0.42%;
    height: 19.35%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect2.howtostart-rect2-initial5 {
    top: 153.8%;
    width: 0.42%;
    height: 19.35%;
    left: 48%;
    opacity: 1;
}

.howtostart-rect3 {
    opacity: 1;
}

.howtostart-rect3.howtostart-rect3-initial0 {
    top: 53.8%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
    opacity: 0;
}

.howtostart-rect3.howtostart-rect3-initial1 {
    top: 53.8%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
}

.howtostart-rect3.howtostart-rect3-initial2 {
    top: 61.2%;
    width: 0.42%;
    height: 7.41%;
    left: 49.8%;
}

.howtostart-rect3.howtostart-rect3-initial3 {
    top: 61.2%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
}

.howtostart-rect3.howtostart-rect3-initial3b {
    top: 61.2%;
    width: 0.42%;
    height: 5.56%;
    left: 48%;
}

.howtostart-rect3.howtostart-rect3-initial4 {
    top: 67.5%;
    width: 0.42%;
    height: 5.56%;
    left: 47%;
    transform: rotate(-45deg);
}

.howtostart-rect3.howtostart-rect3-initial5 {
    top: 69%;
    width: 0.42%;
    height: 5.56%;
    left: 46.5%;
    transform: rotate(-90deg);
}

.howtostart-rect3.howtostart-rect3-initial6 {
    top: 75%;
    width: 0.42%;
    height: 8.8%;
    left: 45.2%;
    transform: rotate(-90deg);
}

.howtostart-rect4 {
    opacity: 1;
}

.howtostart-rect4.howtostart-rect4-initial0 {
    top: 61.2%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
    opacity: 0;
}

.howtostart-rect4.howtostart-rect4-initial1 {
    top: 61.2%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
}

.howtostart-rect4.howtostart-rect4-initial2 {
    top: 68.61%;
    width: 0.42%;
    height: 7.41%;
    left: 49.8%;
}

.howtostart-rect4.howtostart-rect4-initial3 {
    top: 68.61%;
    width: 0.42%;
    height: 5.56%;
    left: 49.8%;
}

.howtostart-rect4.howtostart-rect4-initial3b {
    top: 68.61%;
    width: 0.42%;
    height: 5.56%;
    left: 48%;
}

.howtostart-rect4.howtostart-rect4-initial4 {
    top: 67.5%;
    width: 0.42%;
    height: 5.56%;
    left: 49%;
    transform: rotate(45deg);
}

.howtostart-rect4.howtostart-rect4-initial5 {
    top: 69%;
    width: 0.42%;
    height: 5.56%;
    left: 49.2%;
    transform: rotate(90deg);
}

.howtostart-rect4.howtostart-rect4-initial6 {
    top: 75%;
    width: 0.42%;
    height: 8.8%;
    left: 49.9%;
    transform: rotate(90deg);
}


.howtostart-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.howtostart-icons img{
    position: absolute;
    transition: all .5s;
}

.howtostart-icon1{
    width: 12.4%;
    height: 6.39%;
    top: 28.24%;
    left: 55.52%;
}

.howtostart-icon1.howtostart-icon1-initial0{
    top: 46.02%;
    left: 138.23%;
}

.howtostart-icon1.howtostart-icon1-initial1{
    top: 46.02%;
    left: 88.23%;
}

.howtostart-icon1.howtostart-icon1-initial2{
    top: 46.02%;
    left: 55.52%;
}

.howtostart-icon2{
    width: 11.67%;
    height: 7.31%;
    top: 35.09%;
    left: 55.83%;
}

.howtostart-icon2.howtostart-icon2-initial0{
    top: 52.87%;
    left: 138.54%;
}

.howtostart-icon2.howtostart-icon2-initial1{
    top: 52.87%;
    left: 88.54%;
}

.howtostart-icon2.howtostart-icon2-initial2{
    top: 52.87%;
    left: 55.83%;
}

.howtostart-icon3{
    width: 10.52%;
    height: 8.15%;
    top: 42.41%;
    left: 56.77%;
}

.howtostart-icon3.howtostart-icon3-initial0{
    top: 60.19%;
    left: 139.48%;
}

.howtostart-icon3.howtostart-icon3-initial1{
    top: 60.19%;
    left: 89.48%;
}

.howtostart-icon3.howtostart-icon3-initial2{
    top: 60.19%;
    left: 56.77%;
}

.howtostart-icon4{
    width: 10.31%;
    height: 6.76%;
    top: 49.72%;
    left: 56.46%;
}

.howtostart-icon4.howtostart-icon4-initial0{
    top: 67.5%;
    left: 139.17%;
}

.howtostart-icon4.howtostart-icon4-initial1{
    top: 67.5%;
    left: 89.17%;
}

.howtostart-icon4.howtostart-icon4-initial2{
    top: 67.5%;
    left: 56.46%;
}

.howtostart-texts {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.howtostart-texts p{
    position: absolute;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(2.31vh, 1.3vw); /*25px*/
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: all .5s;

    color: #FFFFFF;
}

.howtostart-text1{
    width: 27.24%;
    height: 5.56%;
    top: 28.7%;
    left: 9.38%;
}

.howtostart-text1.howtostart-text1-initial0{
    top: 46.39%;
    left: -48.23%;
}

.howtostart-text1.howtostart-text1-initial1{
    top: 46.39%;
    left: 1.77%;
}

.howtostart-text1.howtostart-text1-initial2{
    top: 46.39%;
    left: 9.38%;
}

.howtostart-text2{
    width: 27.24%;
    height: 5.56%;
    top: 36.11%;
    left: 9.38%;
}

.howtostart-text2.howtostart-text2-initial0{
    top: 53.8%;
    left: -48.23%;
}

.howtostart-text2.howtostart-text2-initial1{
    top: 53.8%;
    left: 1.77%;
}

.howtostart-text2.howtostart-text2-initial2{
    top: 53.8%;
    left: 9.38%;
}

.howtostart-text3{
    width: 27.24%;
    height: 5.56%;
    top: 43.52%;
    left: 9.38%;
}

.howtostart-text3.howtostart-text3-initial0{
    top: 61.2%;
    left: -48.23%;
}

.howtostart-text3.howtostart-text3-initial1{
    top: 61.2%;
    left: 1.77%;
}

.howtostart-text3.howtostart-text3-initial2{
    top: 61.2%;
    left: 9.38%;
}

.howtostart-text4{
    width: 27.24%;
    height: 5.56%;
    top: 50.93%;
    left: 9.38%;
}

.howtostart-text4.howtostart-text4-initial0{
    top: 68.61%;
    left: -48.23%;
}

.howtostart-text4.howtostart-text4-initial1{
    top: 68.61%;
    left: 1.77%;
}

.howtostart-text4.howtostart-text4-initial2{
    top: 68.61%;
    left: 9.38%;
}

.howtostart-earn-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: -20.74%;
    overflow: hidden;
    transition: all .5s;
}

.howtostart-earn-wrapper.howtostart-earn-wrapper-initial0{
    top: -28.24%;
}

.howtostart-earn{
    position: absolute;
    bottom:0%;
    width: 19.06%;
    height: 28.33%;
    left: 38.39%;
    transition: all .5s;
}

.howtostart-earn.howtostart-earn-initial0{
    bottom: -50%;
}
