.socialwidget-wrapper.wrtmain-section-wrapper {
    position: fixed;
    top: 0;
    z-index: 11;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all .45s;
}

.socialwidget-wrapper.wrtmain-section-wrapper.socialwidget-wrapper-show {
    visibility: visible;
    opacity: 1;
}

.socialwidget {
    transition: all .5s;
}


.socialwidget-button {
    position: absolute;
    width: 3.02%;
    height: 5.37%;
    left: 93%;
    top: 90%;
    transition: all .5s;
    background-size: contain;
    pointer-events: all;
    opacity: 1;
    visibility: visible;
}


.socialwidget-button.socialwidget-button-middle {
    width: 3.83%;
    height: 6.81%;
    top: 44%;
    left: 92%;
}

.socialwidget-button.socialwidget-button-gone {
    width: 3.83%;
    height: 6.81%;
    top: 44%;
    left: 92%;
    opacity: 0;
    visibility: hidden;
}


.socialwidget-button-icon {
    position: absolute;
    width: 50%;
    top: 25%;
    left: 22%;
    opacity: 1;
    transition: all .45s;
}

.socialwidget-button:hover .socialwidget-button-icon {
    transform: scale(1.3);
}

.socialwidget-button.socialwidget-button-middle .socialwidget-button-icon {
    opacity: 0;
}

.socialwidget-button.socialwidget-button-gone .socialwidget-button-icon {
    opacity: 0;
}

.socialwidget-modal {
    position: absolute;
    width: 300%;
    left: -100%;
    top: 0%;
    height: 100vh;
    background: #000;
    opacity: 0;
    transition: all .8s;
    pointer-events: none;
}

.socialwidget-modal.socialwidget-modal-show{
    opacity: .4;
    pointer-events: all;
}

.socialwidget-container{
    position: absolute;
    width: 3.83%;
    height: 6.81%;
    top: 44%;
    left: 92%;
    visibility: hidden;
    opacity: 0;
    transition: all .45s;
}


.socialwidget-container.socialwidget-container-show1,
.socialwidget-container.socialwidget-container-show2{
    visibility:  visible;
    opacity: 1;
}

.socialwidget-link{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .5;
    left: 0;
    top: 0;
    pointer-events: all;
    cursor: pointer;
    transition: all .45s;
    transform: scale(0.75);
}

.socialwidget-link:hover{
    opacity: 1;
    transform: scale(1);
}

.socialwidget-link img{
    width: 100%;
}

.socialwidget-container-show2 .link-twitter{
    top: -200%;
}

.socialwidget-container-show2 .link-linkedin{
    top: -100%;
}

.socialwidget-container-show2 .link-message{
    top: 0%;
}

.socialwidget-container-show2 .link-instagram{
    top: 100%;
}

.socialwidget-container-show2 .link-telegram{
    top: 200%;
}

.socialwidget-container-show2 .link-discord{
    top: 300%;
}
