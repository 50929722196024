.wrtmember{
    position: absolute;
    transition: all .5s;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    overflow:hidden;
}

.wrtmember:hover{
    transform: scale(1.33);
}

.wrtmember-glow{
    position: absolute;
}

.wrtmember-portrait{
    position: absolute;
}

.wrtmember p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
}

.wrtmember-title{
    font-weight: 900;
    width: 100%;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    text-align: center;
    text-transform: uppercase;
    margin-top: 1em;

    color: #4DBFFF;
}

.wrtadvisor .wrtmember-title{
    display: none;
}

.wrtmember-name{
    font-weight: 700;
    width: 100%;
    font-size: min(1.39vh, .78vw); /*15px*/
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2em;
    color: #fff;
}

.wrtadvisor .wrtmember-name{
    color: #58D0D2;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    margin-top: 2.6em;
}

.wrtmember-description{
    font-weight: 500;
    width: 100%;
    font-size: min(1.39vh, .78vw); /*15px*/
    white-space: pre;
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 2em;
    min-height: 2.3em;
    color: #fff;
}
