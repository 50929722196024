.team.wrtmain-section-wrapper {
    height: 112.5vw;
    max-height: 200vh;
    border-bottom: none;
}

.team-content.wrtmain-section-content {
    height: 112.5vw;
    max-height: 200vh;
}

.team-header {
    position: absolute;
    width: 100%;
    height: 16.3%;
    left: 0%;
    top: 2.92%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: min(27.78vh, 15.63vw); /*300px*/

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.405em;
    text-transform: uppercase;

    background: linear-gradient(180deg, #0AE7FF 0%, #AD2AFA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    mix-blend-mode: normal;
}

.team-subheader {
    position: absolute;
    width: 100%;
    height: 4.86%;
    top: 68.33%;
    left: 2.6%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: min(8.33vh, 4.69vw);/*90px*/
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.35em;
    justify-content: center;
    text-transform: uppercase;

    background: linear-gradient(270deg, rgba(0, 255, 255, 0) 32.76%, #76EDED 49.79%, rgba(0, 255, 255, 0) 69.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.team-node {
    position: absolute;
    z-index: 2;
    transition: all .5s;
    width: 15%;
    height: 15%;
}

.team-portrait {
    width: 100%;
}

.team-desc {
    width: 100%;
    height: 25%;
}

.team-node-1 {
    left: 25%;
    top: 20%;
}

.team-node-2 {
    left: 60%;
    top: 20%;
}

.team-node-3 {
    left: 20%;
    top: 40%;
}

.team-node-4 {
    left: 42.5%;
    top: 40%;
}

.team-node-5 {
    left: 65%;
    top: 40%;
}

.team-node-6 {
    left: 20%;
    top: 63%;
}

.team-node-7 {
    left: 42.5%;
    top: 63%;
}

.team-node-8 {
    left: 65%;
    top: 63%;
}

.team-title {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    width: 100%;
    font-size: min(20px, 1.11vw);
    line-height: 1.15;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1em;

    color: #4DBFFF;
}


.team-title.team-title-advisor {
    font-size: min(2.31vh, 1.3vw); /*25px*/
    margin-top: .5em;
    margin-bottom: .5em;
    color: #9F48F5;
}

.team-name {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    font-size: min(15px, .83vw);
    line-height: 1.15;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;
}

.team-role {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    font-size: min(15px, .83vw);
    line-height: 1.15;
    text-align: center;
    text-transform: lowercase;
}
