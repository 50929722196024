.wrtmain {
    width: 100%;
    margin: auto;
    z-index: 1;
    background-color: #e3f1f5;
    background-image: url("../../assets/backgrounds/gradient_bottom.webp");
    background-position: center;
    background-position-y: min(105.25vw, 150vh);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.wrtmain-header {
    position: relative;
    top: 10.5px;
    font-weight: bold;
    font-size: 1.5em;
}

.wrtmain-section-wrapper {
    /* height: 100vh;
    max-height: 80vw; */
    height: 56.25vw; /* 1080/1920 = 0.5625 */
    max-height: calc(100vh + 15px);
    width: 100vw;
    position: relative;
    margin: -15px 0 0 0;
    /* margin-bottom: 50%; */
    padding: 0 0 15px 0;
    border-bottom: solid min(50vw,50vh) transparent;
    box-sizing: content-box;

}

.wrtmain-section-content {
    position: absolute;
    width: 100vw;
    height: 56.25vw; /* 1080/1920 = 0.5625 */
    max-height: calc(100vh + 15px);
    max-width: 177.78vh; /* 1920/1080 = 1.778 */
    margin: auto;
    top:0;
    /* bottom:0; */
    left:0;
    right:0;
    /* border: solid 1px red; */
}
