.nft.wrtmain-section-wrapper{
    height: 168.75vw;
    max-height: 300vh;
    pointer-events: none;
    margin-bottom: 16.67%;
}
.nft-content.wrtmain-section-content {
    height: 168.75vw;
    max-height: 300vh;
}

.nft-content *{
    position: absolute;
}

.nft-content br{
    position: static;
}

.nft-glow {
    position: absolute;
    width: 64.69%;
    height: 34.91%;
    left: 19.17%;
    top: 6.48%;
    transition: all .5s;
    z-index: 2;
}

.nft-glow.nft-glow-initial{
    top: 56.48%;
}

.nft-header {
    position: absolute;
    top: 3.18%;
    height: 22.59%;
    left: 5.63%;
    width: 91.35%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(64.82vh, 36.46vw); /*700px*/
    display: flex;
    align-items: center;

    color: #F5F5F5;

    z-index: 3;
    mix-blend-mode: soft-light;
    transition: all .5s;
}

.nft-header-front {
    display: none;
    opacity: 0.4;
}

.nft-header.nft-header-initial {
    top: 53.18%;
}

.nft-arrow-left {
    position: absolute;
    width: 3.44%;
    height: 6.15%;
    left: 6.4%;
    top: 19.97%;

    cursor: pointer;
    pointer-events: all;
    mix-blend-mode: normal;
    transition: all .5s;
}

.nft-arrow-left.nft-arrow-initial {
    left: -50%
}

.nft-arrow-right {
    position: absolute;
    width: 3.44%;
    height: 6.15%;
    left: 90.31%;
    top: 19.97%;

    cursor: pointer;
    pointer-events: all;
    mix-blend-mode: normal;
    transition: all .5s;
}

.nft-arrow-right.nft-arrow-initial {
    left: 150%
}

.nft-portal {
    position: absolute;
    width: 100%;
    height: 33%;
    top: 0%;
    left: 0%;
    transition: all .5s;
    z-index: 4;
}

.nft-item {
    position: absolute;
    opacity: 1;
    transition: all .5s;
    z-index: 5;
}

.nft-portal-changing .nft-item {
    opacity: 0;
}

.nft-stand {
    position: absolute;
    top: 55.46%;
    transition: all .5s;
    z-index: 7;
}

.nft-portal-changing .nft-stand {
    top: 35%;
}

.nft-portal-left {
    opacity: .5;
}

.nft-portal-left.nft-portal-left-initial {
    top: 100%;
}

.nft-portal-left .nft-item {
    width: 10%;
    left: 24.5%;
    top: 62%;
}

.nft-portal-left .nft-stand {
    width: 31.2%;
    height: 48.89%;
    left: 13.8%;
}

.nft-portal-right {
    opacity: .5;
}

.nft-portal-right.nft-portal-right-initial {
    top: 100%;
}

.nft-portal-right .nft-item {
    width: 10%;
    left: 66%;
    top: 62%;
}

.nft-portal-right .nft-stand {
    width: 31.2%;
    height: 48.89%;
    left: 55.47%;
}

.nft-portal-mid .nft-item {
    width: 18%;
    left: 40.5%;
    top: 58%;
}

.nft-stand-bot {
    width: 63.7%;
    height: 86.39%;
    left: 17.81%;
    top: 47.59%;
    z-index: 7;
}

.nft-stand-top {
    width: 23.9%;
    height: 49.8%;
    left: 39.17%;
    top: 43.89%;
    z-index: 7;
    transition: all .5s;
}

.nft-stand-glow {
    width: 24.1%;
    height: 37.59%;
    left: 37.66%;
    top: 54.17%;
    z-index: 6;
}

.nft-stand-top-glow {
    width: 17.34%;
    height: 16.57%;
    left: 41.35%;
    top: 53.06%;
    z-index: 6;
    transition: all .5s;
}

.nft-portal-changing .nft-stand-top {
    top: 73.15%;
}

.nft-portal-changing .nft-stand-top-glow {
    top: 83.24%;
}

.nft-portal.nft-portal-fake {
    top: 33% !important;
    opacity: 1 !important;
    transition: all .5s;
}

.nft-portal.nft-portal-fake-initial {
    opacity: 0 !important;
}

.nft-portal-fake .nft-item {
    width: 30%;
    left: 53%;
    top: 8%;
}

.nft-portal-fake .nft-stand {
    width: 77.81% !important;
    height: 105.56% !important;
    left: 28.7% !important;
    top: 7.56% !important;
}

.nft-text1 {
    position: absolute;
    width: 34.22%;
    height: 15.56%;
    left: 12.71%;
    top: 40.56%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: pre;
    transition: all .5s;

    color: #FFFFFF;
}

.nft-text1.nft-text1-initial {
    left: -60%;
}

.nft-text2 {
    position: absolute;
    width: 74.11%;
    height: 13.09%;
    left: 12.71%;
    top: 53.58%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(2.31vh, 1.3vw); /*25px*/
    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.nft-glow2 {
    width: 55.05%;
    height: 36.11%;
    left: -11.2%;
    top: 32.01%;
    transition: all .5s;
    transform: rotate(-90deg);
}

.nft-glow2.nft-glow2-initial {
    left: -60%;
}

.nft-text3 {
    position: absolute;
    width: 37.08%;
    height: 14%;
    left: 50%;
    top: 76.02%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(3.7vh, 2.08vw);/*40px*/
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: all .5s;

    color: #FFFFFF;
}

.nft-text3.nft-text3-initial {
    left: 100%;
}

.nft-portal.nft-portal-down {
    top: 67% !important;
    opacity: 1 !important;
    transition: all .5s;
}

.nft-portal-down .nft-item {
    width: 25%;
    left: 17%;
    top: 20%;
}

.nft-portal-down .nft-stand {
    width: 73.85%;
    height: 100.19%;
    left: -8.44%;
    top: 13.15%;
}

.nft-portal-down .nft-stand-glow {
    width: 17.14%;
    height: 30.83%;
    left: 19.32%;
    top: 33.61%;
}

.nft-portal.nft-portal-down.nft-portal-down-initial {
    left: -100%;
}

.nft-glow3 {
    position: absolute;
    width: 55.05%;
    height: 36.11%;
    top: 66.42%;
    left: 46.93%;

    transition: all .5s;
    transform: rotate(90deg);
}

.nft-glow3.nft-glow3-initial {
    left: 160%;
}

.nft-portal-left .nft-item {
    animation: simpleFloating 7s linear -5s infinite;
}

.nft-portal-mid .nft-item {
    animation: simpleFloating 5s linear infinite;
}

.nft-portal-right .nft-item,
.nft-portal-fake .nft-item {
    animation: simpleFloating 6s linear -3s infinite;
}

.nft-portal-down .nft-item {
    animation: simpleFloating 5s linear -1s infinite;
}

.nft-part {
    position: absolute;
    height: 33.3333%;
    width: .1%;
    left: 0;
    z-index: 10;
    pointer-events: none;
}

.nft-part1 {
    top: 0;
}

.nft-part2 {
    top: 33.3334%;
}

.nft-part3 {
    top: 66.6667%;
}

@keyframes floating {
    0% { transform: translate(-50%,  0) rotate(0.01deg); }
    12.5% { transform: translate(-50%,  2.5%) rotate(8.01deg); }
    25% { transform: translate(-50%,  5%) rotate(3.01deg); }
    37.5% { transform: translate(-50, 2.5%) rotate(-8.01deg); }
    50% { transform: translate(-50%, 0) rotate(-1.01deg); }
    62.5% { transform: translate(-50%,  -2.5%) rotate(4.01deg); }
    75% { transform: translate(-50%,  -5%) rotate(9.01deg); }
    87.5% { transform: translate(-50%,  -2.5%) rotate(4.01deg); }
    100% { transform: translate(-50%, 0) rotate(0.01deg); }
}

@keyframes simpleFloating {
    0% {transform:translateY(0%) rotate(0.01deg);}
    50% {transform:translateY(2%) rotate(0.01deg);}
    100% {transform: translateY(0%) rotate(0.01deg);}
}
