.navbar-wrapper.wrtmain-section-wrapper {
    position: fixed;
    top: 0;
    z-index: 10;
    pointer-events: none;
    margin-top: 5px;
}

.navbar-wrapper.navbar-wrapper-blended {
    mix-blend-mode: soft-light;
}

.navbar {
    /* position: relative;
    left: 0px;
    max-width: 1600px;
    height: 100%;
    margin: auto; */
    transition: all .5s;
}

.navbar.navbar-subpage {
    left: -10%;
}

.navbar-rect {
    position: absolute;
    top: 3.8%;
    left: 74.04%;
    width: 13.14%;
    height: 6.85%;
    transition: left .5s, opacity .5s;
    border: 2px solid #FFFFFF;
    border-radius: 6px;
    pointer-events: all;
}

.navbar-rect:hover {
    background: #fff;
}

.navbar-rect.navbar-rect-initial0 {
    left: 100%;
    width: 21.69%;
}

.navbar-rect.navbar-rect-initial1 {
    left: 44.44%;
    width: 21.69%;
}

.navbar-rect.navbar-rect-subpage {
    opacity: 0;
}

.navbar-rect-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 16.25%;
    overflow: hidden;
}

.navbar-rect-wrapper.navbar-rect-wrapper-initial {
    width: 9.84%;
}

.navbar-drawer {
    position: absolute;
    overflow: hidden;
    left: 12.08%;
    top: 0%;
    height: 100%;
    width: 100%;
}

.navbar-drawer .navitem {
    top: 6.48%;
    opacity: 0;
}

.navbar-drawer.navbar-drawer-open .navitem {
    opacity: 1;
}

.navbar-drawer .navitem-download {
    left: -83.39%;
}

.navbar-drawer.navbar-drawer-open .navitem-download {
    left: 4.53%;
}

.navbar-drawer .navitem-metaverse {
    left: -73.33%;
}

.navbar-drawer.navbar-drawer-open .navitem-metaverse {
    left: 14.58%;
}

.navbar-drawer .navitem-whitepaper {
    left: -60.73%;
}

.navbar-drawer.navbar-drawer-open .navitem-whitepaper {
    left: 27.19%;
    width: 6%;
    height: 2%;
}

.navbar-drawer .navitem-vesting {
    left: -51.87%;
}

.navbar-drawer.navbar-drawer-open .navitem-vesting {
    left: 36.04%;
}

.navbar-logo {
    position: absolute;
    transition: all .5s;
    width: 13.02%;
    height: 32.78%;
    left: 16.51%;
    top: -13.06%;
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
}

.navbar-logo.navbar-logo-initial0 {
    width: 53.39%;
    height: 134.25%;
    left: 11.2%;
    top: -34.26%;
}

.navbar-logo.navbar-logo-initial1 {
    width: 13.02%;
    height: 32.78%;
    left: 11.3%;
    top: -13.06%;
}

.navbar-logo.navbar-logo-open {
    left: 64.84%;
}

.navbar-logo.navbar-logo-subpage {
    opacity: .4;
    left: 2.14%;
}

.navbar-logo.navbar-logo-vesting {
    left: 2.14%;
}

.navbar-logo.navbar-logo-disabled {
    cursor: not-allowed;
}

.navbar-line {
    position: absolute;
    transition: all .5s;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px #FFFFFF;
    border-radius: 5.5px;
    width: .26%;
    height: 6.85%;
    left: 16.25%;
    top: 3.8%;
}

.navbar-line.navbar-line-initial0 {
    width: .26%;
    height: 25.74%;
    left: -100%;
    top: 55.09%;
}

.navbar-line.navbar-line-initial1 {
    width: .26%;
    height: 25.74%;
    left: 13.07%;
    top: 55.09%;
}

.navbar-line.navbar-line-initial2 {
    width: .26%;
    height: 25.74%;
    left: 10.05%;
    top: 55.09%;
}

.navbar-line.navbar-line-initial3 {
    width: .36%;
    height: 5.74%;
    left: 10.05%;
    top: 4.26%;
}

.navbar-line.navbar-line-initial4 {
    width: .26%;
    height: 6.85%;
    left: 10.05%;
    top: 3.8%;
}

.navbar-line.navbar-line-open {
    left: 55%;
}

.navbar-line.navbar-line-form1 {
    width: 0.52%;
    height: 7.5%;
    left: 49.75%;
    top: 18.39%;
    transform: rotate(90deg);
}

.navbar-line.navbar-line-form2 {
    width: 0.26%;
    height: 105%;
    left: 49.88%;
    top: -30.5%;
    transform: rotate(90deg);
}

.navbar-line.navbar-line-form3 {
    width: 0.26%;
    height: 25.37%;
    left: 49.88%;
    top: 9.6%;
    transform: rotate(90deg);
}

.navbar-line.navbar-line-demo1 {
    width: 0.47%;
    height: 85%;
    left: 49.74%;
    top: 6.11%;
}

.navbar-line.navbar-line-demo2 {
    width: 0.16%;
    height: 30.56%;
    left: 19.48%;
    top: 31.76%;
}

.navbar-line.navbar-line-demo3 {
    width: 0.16%;
    height: 30.56%;
    left: 19.48%;
    top: 31.76%;
    transform: rotate(-90deg);
}

.navbar-line.navbar-line-verse{
    opacity: 0;
}

.navbar-line-vesting1{
    width: 0.26%;
    height: 100.83%;
    left: 49.87%;
    top: -23.65%;
    transform: rotate(90deg);
}

.navbar-line-vesting2{
    width: 0.31%;
    height: 24.17%;
    left: 49.845%;
    top: 7.08%;
    transform: rotate(90deg);
}


.navbar-name{
    position: absolute;
    left: 77.24%;
    top: 5.56%;
    width: 14.48%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: min(3.24vh,1.82vw); /*35px*/

    opacity: 1;
    transition: all .5s;
    color: #FFFFFF;
}

.navbar-name.navbar-name-initial0{
    opacity: 0;
}

.navbar-name.navbar-name-initial1{
    left: 71.93%;
    opacity: 1;
}

.navbar-name.navbar-name-subpage{
    opacity: 0;
}

.navbar-name-end0{
    top: 10.65%;
    left: 64.84%;
    width: 31.04%;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    text-align: center;
}

.navbar-name-end1{
    top: 10.65%;
    left: 64.84%;
    width: 31.04%;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    text-align: center;
    opacity: 0;
}
