.app {
    background-color: #77c4ff;
    position: absolute;
    height: 100%;
    width: 100%;
}

.app *{
    box-sizing: border-box;
    line-height: 1.15;
    margin: 0;
    padding: 0;
}

.main-container {
    width: 100%;
}

.wrtmain-container{
    overflow: hidden;
}

.content-container {
    width: 100%;
    height: 100%;
    background: #E5E5E5;
    position: relative;
}

.color-purple {
    color: rgb(127,71,221) !important;
}

#env-name {
    position: absolute;
    background-color: white;
    z-index: 10;
    bottom: 20px;
    right: 60px;
}

.wrtmobile {
    display: none;
}

@media only screen and (max-width: 768px){
    .wrtmain-container {
        display: none;
    }
    .wrtmobile {
        display: block;
    }
}
