.temp {
    width: 100%;
    margin: auto;
    z-index: 1;
}

.temp-header {
    position: relative;
    top: 10.5px;
    font-weight: bold;
    font-size: 1.5em;
}
