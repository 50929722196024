.sports {
}

.sports-neon {
    position: absolute;
    width: 188.65%;
    height: 255.28%;
    left: -44.38%;
    top: -67.22%;
    pointer-events: none;
}

.sports-text {
    position: absolute;
    left: 25.94%;
    top: 85.83%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    text-transform: uppercase;
    white-space: pre;
    color: #FFFFFF;
}

.sports-header {
    position: absolute;
    top: 15.41%;
    left: 28.65%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    letter-spacing: 0.085em;
    text-transform: uppercase;
    white-space: pre;
    color: #FFFFFF;

    opacity: 1;
    transition: all .7s;
}

.sports-header.sports-header-initial{
    left: -100%;
    opacity: 0;
}

.sports-categories {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    opacity: 1;
    transition: all .7s;
}

.sports-categories.sports-categories-initial {
    left: 100%;
    opacity: 0;
}

.sports-gif {
    position: absolute;
    left: 60%;
    height: 14%;
}

.sports-gif-walking{
    top: 8.5%;
}
.sports-gif-running{
    top: 13.6%;
}
.sports-gif-swimming{
    top: 19%;
}
.sports-gif-cycling{
    top: 26%;
}
.sports-gif-crossfit{
    top: 31.5%;
}
.sports-gif-tennis{
    top: 36.8%;
}
.sports-gif-yoga{
    top: 42%;
}
.sports-gif-general{
    top: 47.5%;
}
.sports-gif-strength{
    top: 53.2%;
}
.sports-gif-interval {top:59.2%;}

.sports-name {
    position: absolute;
    left: 67.5%;
    height: 4.44%;
    width: 11.5%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
.sports-name-walking{
    top: 14.35%;
}
.sports-name-running{
    top: 19.72%;
}
.sports-name-swimming{
    top: 25.09%;
}
.sports-name-cycling{
    top: 30.46%;
}
.sports-name-crossfit{
    top: 35.83%;
}
.sports-name-tennis{
    top: 41.2%;
}
.sports-name-yoga{
    top: 46.57%;
}
.sports-name-general{
    top: 51.94%;
}
.sports-name-strength{
    top: 57.81%;
}
.sports-name-interval{
    top: 63.31%;
}
