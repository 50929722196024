.footer {
    position: relative;
    width: 100%;
    color: #fff;
    height: 50vw;
}

.footer * {
    z-index: 2;
}

.footer-neon {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    pointer-events: none;
}

.footer-logo {
    position: absolute;
    width: 10%;
    top: 17%;
    left: 12%;
    opacity: .5;
}

.footer-logo:hover {
    opacity: 1;
}

.footer-header {
    position: absolute;
    top: 26%;
    left: 11.5%;
    margin: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(3.24vh,1.82vw); /*35px*/
    line-height: 1.17;

    color: #FFFFFF;
    opacity: 0.5;
}

.footer-header:hover {
    opacity: 1;
}

.footer-left {
    position: absolute;
    left: 13%;
    top: 40%;
    width: 30%;
    height: 20%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}

.footer-right {
    position: absolute;
    left: 30%;
    top: 40%;
    width: 30%;
    height: 15%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}

.footer .navitem {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    float: none;
    opacity: .5;
    justify-content: flex-start;
}

.footer .navitem > a {
    position: absolute;
    width: 10em;
    height: 2em;
    top: -1em;
    left: 0;
}

.footer .navitem:hover {
    opacity: 1;
}

.footer .navitem > span {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 2;
    height: 2em;

    /* identical to box height */
    letter-spacing: 0.2em;

    color: #FFFFFF;
    text-transform: uppercase;
    transition: all .5s;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    color: #fff;
    letter-spacing: 0.21em;
}

.footer .navitem:hover > span {
    transform: none;
}
