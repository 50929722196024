.navitem {
    position: absolute;
    pointer-events: all;
    transition: all .5s;
    cursor: pointer;
}

.navitem > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: all;
    z-index: 6;
}

.navitem > span {
    display: inline-block;
    position: absolute;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: min(1.39vh, .78vw); /*15px*/
    font-weight: 700;
    letter-spacing: 0.2em;

    color: #FFFFFF;
    text-transform: uppercase;
    transition: all .5s;
    pointer-events: all;
    white-space: pre;
    z-index: 5;
}

.navitem:hover > span {
    transform: scale(1.2);
}
