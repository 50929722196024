.roadmap {
}

.roadmap-content {
}

.roadmap-header {
    position: absolute;
    width: 99.9%;
    height: 40.74%;
    left: 0.1%;
    top: 4.63%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: min(18.52vh, 10.42vw); /*200px*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    z-index: 2;

    color: #FFFFFF;

    mix-blend-mode: soft-light;
    transition: top .5s;
    pointer-events: none;
}

.roadmap-header.active {
    top: -10.37%;
}

.roadmap-neon {
    position: absolute;
    width: 82%;
    left: 9%;
    top: 48.5%;
    z-index: 3;
    pointer-events: none;
}

.roadmap-scar{
    position: absolute;
    pointer-events: none;
    z-index: 3;
}

.roadmap-scar1{
    width: 68.54%;
    height: 12.59%;
    left: 21.09%;
    top: 51.76%;
}

.roadmap-scar2{
    width: 68.54%;
    height: 12.59%;
    left: 10.42%;
    top: 52.32%;
    transform: rotate(-180deg);
}

.roadmap-glow {
    position: absolute;
    width: 102.6%;
    height: 159.35%;
    left: -0.1%;
    top: -98.1%;
    transform: rotate(0.8deg);
    pointer-events: none;
    z-index: 1;
}

.roadmap-glow.dark {
    opacity: 0;
}

.roadmap-container {
    position: absolute;
    width: 84%;
    left: 8%;
    height: 56%;
    z-index: 4;
    /* border: solid 1px red; */
    overflow: hidden;
}

.roadmap-container-top {
    top: 0%;
}

.roadmap-container-bottom {
    top: 61%;
}

.roadmap-drawer {
    position: absolute;
    width: 17%;
    height: 100%;
    /* border: solid 1px green; */
    transition: all .5s;
    display: flex;
    flex-flow: column nowrap;
}

.roadmap-container-top .roadmap-drawer {
    top: 100%;
    padding-top: 22%;
    justify-content: flex-start;
}

.roadmap-container-top .roadmap-drawer.active {
    top: 0%;
}

.roadmap-container-bottom .roadmap-drawer {
    top: -100%;
    padding-bottom: 20%;
    justify-content: flex-end;
}

.roadmap-container-bottom .roadmap-drawer.active {
    top: 0%;
}

.roadmap-drawer-bar {
    position: absolute;
    /* top: 0%; */
    width: 100%;
}

.roadmap-drawer.active .roadmap-drawer-bar {
    top: 50%;
}

.roadmap-container-bottom .roadmap-drawer-bar {
    /* top: 0%; */
}

.roadmap-container-bottom .roadmap-drawer.active .roadmap-drawer-bar {
    top: 50%;
}

.roadmap-drawer-par {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(1.39vh, .78vw); /*15px*/
    line-height: 1.6;
    text-transform: uppercase;
    margin: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.roadmap-drawer.roadmap-drawer-1 {
    left: 0%;
    color: #853FFF;
}

.roadmap-drawer.roadmap-drawer-1 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-1 .roadmap-drawer-par:nth-child(even) {
    color: #AE80FF;
}

.roadmap-drawer.roadmap-drawer-2 {
    left: 21%;
    color: #00FFF7;
}

.roadmap-drawer.roadmap-drawer-2 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-2 .roadmap-drawer-par:nth-child(even)  {
    color: #C0FFFF;
}

.roadmap-drawer.roadmap-drawer-3 {
    left: 42%;
    color: #47EEBC;
}

.roadmap-drawer.roadmap-drawer-3 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-3 .roadmap-drawer-par:nth-child(even)  {
    color: #A5EBD6;
}

.roadmap-drawer.roadmap-drawer-4 {
    left: 62%;
    color: #4795EE;
}

.roadmap-drawer.roadmap-drawer-4 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-4 .roadmap-drawer-par:nth-child(even)  {
    color: #A3BFDE;
}

.roadmap-drawer.roadmap-drawer-4A {
    left: 82%;
    color:#B075F1;
}
.roadmap-drawer.roadmap-drawer-4A .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-4A .roadmap-drawer-par:nth-child(even)  {
    color:  #CEADF3;
}



.roadmap-drawer.roadmap-drawer-5 {
    left: 9%;
    color: #B075F1;
}

.roadmap-drawer.roadmap-drawer-5 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-5 .roadmap-drawer-par:nth-child(even)  {
    color: #CEADF3;
}

.roadmap-drawer.roadmap-drawer-6 {
    left: 30%;
    color: #E147EE;
}

.roadmap-drawer.roadmap-drawer-6 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-6 .roadmap-drawer-par:nth-child(even)  {
    color: #FBCDFF;
}

.roadmap-drawer.roadmap-drawer-7 {
    left: 52%;
    color: #FF00E5;
}

.roadmap-drawer.roadmap-drawer-7 .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-7 .roadmap-drawer-par:nth-child(even)  {
    color: #FC79EF;
}

.roadmap-drawer.roadmap-drawer-7A {
    left: 72%;
    color: #853FFF;
}

.roadmap-drawer.roadmap-drawer-7A .roadmap-drawer-header-year, .roadmap-drawer.roadmap-drawer-7A .roadmap-drawer-par:nth-child(even)  {
    color: #AE80FF;
}

.roadmap-drawer-header {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: baseline;
    width: 100%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    transition: all .5s;
}

.roadmap-container-top .roadmap-drawer-header {
    top: -25%;
}

.roadmap-container-top .roadmap-drawer.active .roadmap-drawer-header {
    top: 35%;
}

.roadmap-container-bottom .roadmap-drawer-header {
    top: 110%;
}

.roadmap-container-bottom .roadmap-drawer.active .roadmap-drawer-header {
    top: 52%;
}

.roadmap-drawer-header-quarter {
    font-size: min(6.48vh, 3.65vw); /*70px*/
}

.roadmap-drawer-header-year {
    font-size: min(3.24vh,1.82vw); /*35px*/
    margin-right: auto;
}

.roadmap-drawer-header-dot{
    width: 10%;
    margin-right: auto;
    opacity: 1;
    transition: all .5s;
}

.roadmap-drawer.active .roadmap-drawer-header-dot{
    opacity: 0;
}
