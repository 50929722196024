.body-verse {
    height: 112.5vw;
    max-height: 201vh;
    overflow: hidden;
}

.body-verse-small {
    height: 56.25vw;
    max-height: 101vh;
    overflow: hidden;
}

.promo-banner {
    opacity: 1;
    transition: all .5s;
    overflow: hidden;
    border-bottom: none !important;
}

.body-verse .promo-banner.wrtmain-section-wrapper {
    height: 113.5vw;
    max-height: 200vh;
    border-bottom: none;
}

.body-verse .intro-content.wrtmain-section-content {
    height: 113.5vw;
    max-height: 200vh;
}

.body-verse-small .promo-banner.wrtmain-section-wrapper {
    /* height: 56.25vw;
    max-height: 100vh; */
    border-bottom: none;
}

.body-verse-small .intro-content.wrtmain-section-content {
    /* height: 56.25vw;
    max-height: 100vh; */
}

.promo-banner.promo-banner-end {
    opacity: 0;
}

.promo-banner.promo-banner-end {
    opacity: 0;
}

.intro-gradient{
  z-index: 1;
  position: absolute;
  width: 110%;
  height: 110%;
  top: -5%;
  bottom: 0;
  left: -5%;
  right: 0;
  transition: all .5s;
  pointer-events: none;
  filter: blur(10px);
}

.intro-background {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 175%;
    left: 0;
    right: 0;
    top: -47.78%;
    transition: all .5s;
    mix-blend-mode: screen;
    pointer-events: none;
}

.intro-background.intro-background-initial1 {
    top: 20%;
}

.intro-background.intro-background-initial2 {
    top: -12.69%;
}

.intro-background.intro-background-subpage {
    top: -6.57%;
}


.body-verse .intro-background {
    height: 87.5%;
    top: 3.29%;
}

.intro-blackness {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #04061B;
    transition: all .5s;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
}

.intro-blackness.intro-blackness-subpage {
    opacity: 1;
}

.stuffList {
    display: none;
    position: relative;
    overflow: scroll;
    top: 22.5px;
    height: calc(100% - 71.25px);
}

.led-phone {
    z-index: 3;
    position: absolute;
    top: 9.44%;
    left: 64.58%;
    height: 76.11%;
    width: 25.63%;
    transition: all .5s;
}


.led-phone.phone-form1 {
    width: 19.43%;
    height: 57.78%;
    left: 40.26%;
    top: 21.85%;
}

.led-phone.phone-form2 {
    width: 41.56%;
    height: 123.43%;
    left: 29.22%;
    top: 1.1%;
    transform: rotate(-90deg);
}

.led-phone.phone-demo1 {
    width: 26.2%;
    height: 77.78%;
    left: 36.93%;
    top: 9.54%;
}

.led-phone.phone-demo2 {
    width: 26.2%;
    height: 77.78%;
    left: 36.93%;
    top: 9.54%;
    opacity: 0.2;
}

.led-phone.phone-verse1 {
    width: 41.56%;
    height: 61.72%;
    left: 29.22%;
    top: 10%;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    transform: rotate(-90deg) perspective(14em) rotateY(60deg) scale(.5, .82);
}

.led-phone.phone-verse1 {
    width: 41.56%;
    height: 61.72%;
    left: 29.22%;
    top: 8.9%;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    transform: rotate(-90deg) perspective(8em) rotateY(60deg) scale(.2, .33)
}

.led-phone.phone-vesting {
    opacity: 0;
    pointer-events: none;
}

.intro-text {
    /* display: none; */
    position: absolute;
    width: 43.59%;
    height: 7.59%;
    top: 42.87%;
    left: 12.08%;
    z-index: 3;
    opacity: 1;
    transition: all .5s;
    pointer-events: none;
}

.intro-text.intro-text-initial {
    opacity: 0;
}


.intro-text.intro-text-subpage {
    opacity: 0;
    visibility: hidden;
}

.intro-header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0px 6px #FFFFFF;
    white-space: pre;
}

.intro-subheader {
    position: absolute;
    width: 31.25%;
    height: 8.7%;
    left: 6.16%;
    top: 10.84%;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: min(1.39vh, .78vw); /*15px*/
    line-height: 2.2;
    letter-spacing: 0.55em;
    text-transform: uppercase;
    white-space: pre;

    color: #FFFFFF;
    text-shadow: 2px 2px 2px #02081B;
}


.intro-buttons {
    position: absolute;
    width: 41.46%;
    height: 30.74%;
    left: 10.31%;
    top: 51.76%;
    pointer-events: none;
    overflow: hidden;
    z-index: 3;
    transition: all .5s;
}

.intro-buttons.intro-buttons-initial1 {
    left: -100%;
}

.intro-buttons.intro-buttons-initial2 {
    left: 13.33%;
}

.intro-buttons.intro-buttons-subpage {
    opacity: 0;
    visibility: hidden;
}

.intro-button {
    display: block;
    position: absolute;
    left: 6.53%;
    width: 18.72%;
    height: 11.75%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(1.39vh, .78vw); /*15px*/
    text-align: center;
    text-transform: uppercase;
    pointer-events: all;
    color: #FFFFFF;

    opacity: 0.95;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    border-radius: 1.25em;
    cursor: pointer;
    transition: all .5s;
}
.intro-button:hover  {
  background: rgba(255, 255, 255, 0.45);
}
.intro-button:focus {
 background: rgba(255, 255, 255, 0.75);
}
.intro-button.intro-button-partnership {
    top: 47.29%;
}

.intro-button.intro-button-tryitnow {
    top: 63.25%;
}

.intro-button.intro-button-partnership.intro-button-initial {
    left: -100%;
}

.intro-button.intro-button-tryitnow.intro-button-initial {
    left: -100%;
}

.wrt-animation {
    z-index: 4;
}

.wrt-animation1 {
    width: 48%;
    left: 46.5%;
    bottom: 6.5%;
}

.wrt-animation2 {
    width: 56%;
    left: 45%;
    bottom: .8%;
}

.wrt-animation3 {
    width: 39%;
    left: 60%;
    bottom: 0%;
}

.wrt-animation.wrt-animation-subpage {
    width: 39%;
    left: 60%;
    bottom: 0%;
    opacity: 0;
    visibility: hidden;
}

.intro-line {
    position: absolute;
    width: .4%;
    height: 26%;
    bottom: 15%;
    left: 8.5%;
    z-index: 3;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px #FFFFFF;
    border-radius: 5.5px;
    opacity: 0;
    transition: opacity .5s, left .5s, bottom .5s, height .5s;
}

.intro-preloader {
    position: absolute;
    pointer-events: none;
    z-index: 99;
    height: 1px;
    top: 0px;
}

.intro-preloader img {
    width: 1px;
    height: 1px;
}



.partnership-title {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 22.22%;
    top: 0%;
    left: 0;
    pointer-events: none;
    z-index: 3;
}

.partnership-title-text {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 7.59%;
    transition: all .5s;
    opacity: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: min(6.48vh, 3.65vw); /*70px*/

    /* identical to box height */
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 0px 10px #FFFFFF;
}

.partnership-title-text.partnership-title-form {
    opacity: 1;
    top: 42.92%;
}

.partnership-subtitle {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 23%;
    z-index: 3;
    pointer-events: none;
}

.partnership-subtitle-text {
    position: absolute;
    top: -10%;
    width: 100%;
    transition: all .5s;
    opacity: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(3.24vh,1.82vw); /*35px*/

    /* identical to box height */
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.partnership-subtitle-text.partnership-subtitle-form {
    opacity: 1;
    top: 3%;
}

.partnership {
    z-index: 5;
    display: block;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 38%;
    left: 22%;
    height: 50%;
    width: 56%;
    transition: all .5s;
}

.partnership.partnership-show {
    opacity: 1;
    visibility: visible;
}

.partnership * {
    border: none;
    background-color: transparent;
    outline: none;
    resize: none;
    display: block;
    width: 100%;
    position: absolute;
    margin: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(2.31vh, 1.3vw); /*25px*/
    text-transform: uppercase;

    color: #FFFFFF;
    transition: all .5s;
}

.partnership button {
    opacity: 0;
}

.partnership.partnership-show-inputs * {
    opacity: 1;
}

.partnershipform input:invalid, .partnership textarea:invalid {
    border: none;
    outline: none;
    box-shadow: none;
}

.partnershipform textarea::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

.partnership input[type="text"]::placeholder {
    text-align: center;
    opacity: .5;
    color: #fff;
}
.partnership input[type="text"]:hover::placeholder {
    opacity: 1;
}

.partnership input[type="email"]::placeholder {
    text-align: center;
    opacity: .5;
    color: #fff;
}
.partnership input[type="email"]:hover::placeholder {
    opacity: 1;
}

.partnership textarea::placeholder {
    opacity: .5;
    color: #fff;
}
.partnership textarea:hover::placeholder {
    opacity: 1;
}

.partnership-firstname {
    top: 5%;
    width: 90%;
    margin-left: 5%;
    height: 12.41%;
    background: #FFFFFF0C;
    text-align: center;
}

.partnership-lastname {
    top: 20%;
    width: 90%;
    margin-left: 5%;
    height: 12.41%;
    background: #FFFFFF0C;
    text-align: center;
}

.partnership-email {
    top: 35%;
    width: 90%;
    margin-left: 5%;
    height: 12.41%;
    background: #FFFFFF0C;
    text-align: center;
}

.partnership-message-outer {
    top: 50%;
    width: 90%;
    margin-left: 5%;
    height: 45%;
    background: #FFFFFF0C;
}
.partnership-message-outer .partnership-message {
  padding: 1em;
  padding-right: 0%;
  height:100%;
  width:80%;
}

.partnership-submit {
    width: 7.81%;
    height: 12.59%;
    bottom: 1%;
    right: 4%;
    opacity: .5;
}

.partnership-submit:hover {
    opacity: 1;
}

.submitInput {
    position: absolute;
    left: 0%;
    bottom: 0%;
    height: 2em;
    text-align: left;
    padding: 0;
}

.intro-load {
    display: block;
    position: absolute;
    width: 10%;
    height: 10%;
    margin: 0;
    left: 25%;
    top: 55%;
    font-size: min(3.24vh,1.82vw); /*35px*/
    color: #fff;
    pointer-events: none;
}

.intro-load.intro-load-gone {
    display: none;
}

.demo-text-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 19.48%;
    overflow: hidden;
    z-index: 5;
    pointer-events: none;
}

.demo-title-text {
    position: absolute;
    width: 100%;
    height: 30.56%;
    left: -120%;
    top: 27.69%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 0px 10px #FFFFFF;
    transition: all .5s;
    opacity: 1;
}

.demo-title-text.demo-title-text-demo1 {
    left: -19.64%;
}

.demo-title-text.demo-title-text-demo2 {
    left: -19.64%;
    opacity: 0;
}

.demo-subtitle-text {
    position: absolute;
    width: 100%;
    height: 30.56%;
    left: -19.64%;
    top: 36.48%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: min(3.7vh, 2.08vw);/*40px*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #FFFFFF;

    transition: all .5s;
    opacity: 0;
}

.demo-subtitle-text.demo-subtitle-text-demo1 {
    opacity: 1;
}

.demo-glow-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 19.48%;
    overflow: hidden;
    z-index: 4;
    transition: all .5s;
    pointer-events: none;
    transform-origin: left;
}

.demo-glow-wrapper.demo-glow-wrapper-demo1 {
    transform: translate(4%, -3%) rotate(-90deg);
    width: 0;
}

.demo-glow {
    position: absolute;
    width: 21.3%;
    height: 57.31%;
    left: -100%;
    top: 19.72%;
    pointer-events: none;
    transition: all .5s;
}

.demo-glow.demo-glow-demo1 {
    left: 0%;
}

.demo-phone-glow {
    position: absolute;
    width: 57.71%;
    height: 102.31%;
    left: 21.15%;
    top: -20.56%;
    transform: rotate(180deg);
    transition: all .5s;
    opacity: 0;
    z-index: 3;
    pointer-events: none;
}

.demo-phone-glow.demo-phone-glow-demo {
    opacity: 1;
}

.demo-phone {
    position: absolute;
    width: 20%;
    height: 68%;
    left: 40%;
    top: 15.5%;
    transition: all .5s;
    opacity: 0;
    z-index: 4;
    pointer-events: none;
}

.demo-phone.demo-phone-demo {
    opacity: 1;
}

.demo-frame {
    position: absolute;
    width: 17.5%;
    height: 62%;
    left: 41.25%;
    top: 18.5%;
    transition: all .5s;
    opacity: 0;
    z-index: 5;
    border: none;
    outline: none;
    visibility: hidden;
}

.demo-frame.demo-frame-demo {
    opacity: 1;
    visibility: visible;
}

.demo-watch {
    position: absolute;
    height: 100%;
    left: 0%;
    top: 0%;
    transition: all .5s;
    z-index: 4;
    opacity: 0;
    pointer-events: none;
}

.demo-watch.demo-watch-demo {
    opacity: 1;
    left: 25%;
}

.demo-button-wrapper-top {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -53%;
    left: 0;
    pointer-events: none;
    z-index: 3;
    overflow: hidden;
}

.demo-button-wrapper-bot {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 47%;
    left: 0;
    pointer-events: none;
    z-index: 3;
    overflow: hidden;
}

.demo-button-glow-top {
    position: absolute;
    width: 35.99%;
    left: 4.11%;
    top: 120.83%;
    transition: all .5s;
}

.demo-button-glow-top.demo-button-glow-top-demo {
    top: 70.83%;
}

.demo-button-glow-bot {
    position: absolute;
    width: 35.99%;
    left: -0.78%;
    top: -50%;
    transition: all .5s;
    transform: rotate(180deg);
}

.demo-button-glow-bot.demo-button-glow-bot-demo {
    top: 0%;
}

.demo-button-top {
    position: absolute;
    width: 17.24%;
    height: 6.48%;
    left: 10.94%;
    top: 120%;
    font-size: min(3.24vh,1.82vw); /*35px*/
    background: #FFFFFF25;
    mix-blend-mode: soft-light;
    box-shadow: inset 0px 4px 20px #000000;
    border-radius: 1em;
    pointer-events: all;
    transition: all .5s;
    cursor: pointer;
    z-index: 5;
}

.demo-button-top.demo-button-top-demo {
    top: 90%;
}

.demo-button-top:hover {
    background: #FFFFFFDD;
}

.demo-button-icon-top {
    position: absolute;
    width: 13%;
    top: 15%;
    left: 80%;
}

.demo-button-bot {
    position: absolute;
    width: 17.24%;
    height: 6.48%;
    left: 10.94%;
    top: -20%;
    font-size: min(3.24vh,1.82vw); /*35px*/
    background: #FFFFFF25;
    mix-blend-mode: soft-light;
    box-shadow: inset 0px 4px 20px #000000;
    border-radius: 1em;
    pointer-events: all;
    transition: all .5s;
    cursor: pointer;
    z-index: 5;
}

.demo-button-bot.demo-button-bot-demo {
    top: -20%;
    top: 3.52%;
}

.demo-button-bot:hover {
    background: #FFFFFFDD;
}

.demo-button-icon-bot {
    position: absolute;
    width: 10%;
    top: 15%;
    left: 82%;
}

.demo-button-text {
    position: absolute;
    left: 10%;
    height: 100%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: min(3.24vh,1.82vw); /*35px*/
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.7);

    text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.7);
}

.verse-glow1 {
    position: absolute;
    width: 69.17%;
    height: 61.85%;
    left: 19.95%;
    top: -13.01%;

    z-index: 3;
    pointer-events: none;
    opacity: 0;
}

.verse-glow1.verse-glow-show {
    opacity: 1;
}

.verse-glow2 {
    position: absolute;
    width: 49.38%;
    height: 44.86%;
    left: 26.67%;
    top: 3.61%;

    z-index: 3;
    pointer-events: none;
    opacity: 0;
}

.verse-glow2.verse-glow-show {
    opacity: 1;
}

.verse-poster {
    position: absolute;
    width: 38.23%;
    height: 20.93%;
    left: 30.89%;
    top: 6.3%;

    z-index: 4;
    transition: all .5s;
    pointer-events: none;
    cursor: pointer;
    opacity: 0;
}

.verse-poster.verse-poster-show {
    opacity: 1;
    pointer-events: all;
}

.verse-video {
    position: absolute;
    width: 78.18%;
    height: 39.35%;
    left: 10.94%;
    top: 5.32%;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;

    z-index: 5;
}

.verse-video.verse-video-show {
    opacity: 1;
    pointer-events: all;
}

.verse-scroll {
    position: absolute;
    width: 1.2%;
    height: 2.82%;
    left: 49.38%;
    top: 45.79%;

    pointer-events: none;
    transition: all .5s;
    opacity: 0;
    z-index: 3;
}

.verse-scroll.verse-glow-show {
    opacity: 1;
}

.verse-glow3 {
    position: absolute;
    width: 67.76%;
    height: 91.16%;
    left: -8.18%;
    top: 19.72%;
    transform: scaleY(-1);

    pointer-events: none;
    transition: all .5s;
    opacity: 0;
    z-index: 3;
}

.verse-glow3.verse-glow-show {
    opacity: 1;
}

.verse-glow4 {
    position: absolute;
    width: 67.76%;
    height: 91.16%;
    left: 37.81%;
    top: 19.72%;
    transform: scale(-1, -1);

    pointer-events: none;
    transition: all .5s;
    opacity: 0;
    z-index: 3;
}

.verse-glow4.verse-glow-show {
    opacity: 1;
}

.verse-text {
    position: absolute;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    transition: all .5s;

    text-shadow: 0px 0px 7px #FFFFFF;
}

.verse-text.verse-glow-show {
    opacity: 1;
    pointer-events: all;
}

.verse-text.verse-text1 {
    width: 51.88%;
    height: 6.76%;
    left: 24.64%;
    top: 56.67%;
    font-size: min(2.31vh, 1.3vw); /*25px*/
}

.verse-text.verse-text2 {
    width: 37.08%;
    height: 3.8%;
    left: 31.46%;
    top: 63%;
    font-size: min(1.39vh, .78vw); /*15px*/
}

.verse-text.verse-text3 {
    width: 48.65%;
    height: 9.44%;
    left: 25.68%;
    top: 66.9%;
    font-size: min(2.31vh, 1.3vw); /*25px*/
}

.verse-text.verse-text4 {
    width: 37.08%;
    height: 3.8%;
    left: 31.46%;
    top: 74.5%;
    font-size: min(1.39vh, .78vw); /*15px*/
}

.vesting-glow{
    position: absolute;
    width: 73.59%;
    height: 198.06%;
    top: 0%;
    left: 0;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;
    z-index: 3;
    transform-origin: left top;
    transform: scaleX(-1) rotate(90deg);
}

.vesting-glow-show{
    opacity: 1;
}

.vesting-title{
    position: absolute;
    width: 100%;
    height: 26.76%;
    left: 0%;
    top: 0%;
    pointer-events: none;
    z-index: 4;
    overflow: hidden;

    mix-blend-mode: soft-light;
}

.vesting-title-text{
    position: absolute;
    width: 100%;
    height: 28.03%;
    left: -0.05%;
    top: 129.41%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: min(6.48vh, 3.65vw); /*70px*/
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    transition: all .5s;

    color: #FFFFFF;
}

.vesting-title-text.vesting-title-show{
    top: 29.41%;
}

.vesting-panel{
    position: absolute;
    width: 13.23%;
    height: 13.33%;

    font-size: min(2.5vh, 1.41vw);  /*27px*/
    background: #1B5D86;
    mix-blend-mode: normal;
    box-shadow: inset 0px 0px 10px #000000;
    border-radius: 1em;
    z-index: 5;
    transition: all .5s;
    opacity: 0;
    overflow: hidden;
}

.vesting-panel.vesting-panel-locked{
    left: 117.4%;
    top: 128.61%;
}

.vesting-panel.vesting-panel-locked.vesting-panel-show1{
    left: 17.4%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-locked.vesting-panel-show2{
    left: 38.8%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-claimed{
    left: 143.28%;
    top: 128.61%;
}

.vesting-panel.vesting-panel-claimed.vesting-panel-show1{
    left: 43.28%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-claimed.vesting-panel-show2{
    left: 55.26%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-ready{
    left: 169.38%;
    top: 128.61%;
}

.vesting-panel.vesting-panel-ready.vesting-panel-show1{
    left: 69.38%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-ready.vesting-panel-show2{
    left: 71.93%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel.vesting-panel-total{
    width: 17.97%;
    height: 47.41%;
    left: -100%;
    top: 128.61%;
    box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.59);
}

.vesting-panel.vesting-panel-total.vesting-panel-show2{
    left: 12.86%;
    top: 28.61%;
    opacity: 1;
}

.vesting-panel-total .vesting-panel-glow{
    width: 115.07%;
    height: 89.69%;
    left: -7.83%;
}

.vesting-panel-glow{
    position: absolute;
    width: 125.59%;
    height: 146.53%;
    left: -12.6%;
    top: 0.32%;
    transform: scaleY(-1);
}

.vesting-panel-text{
    position: absolute;
    width: 100%;
    height: 37.5%;
    left: 0%;
    top: 11.81%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: min(1.85vh, 1.04vw); /*20px*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vesting-panel-sum{
    top: 38.19%;

    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.vesting-panel-claimed .vesting-panel-text{
    top: 0.32%;
}

.vesting-panel-total .vesting-panel-text{
    height: 11.13%;
    top: 8.2%;
    font-size: min(4.63vh, 2.6vw); /*50px*/
}

.vesting-panel-total .vesting-panel-sum{
    top: 19.34%;
    font-size: min(2.31vh, 1.3vw); /*25px*/
}

.vesting-panel-total .vesting-panel-wallet{
    width: 73.62%;
    left: 22.32%;
    top: 42.68%;
    font-size: min(1.85vh, 1.04vw); /*20px*/
}

.vesting-panel-total .vesting-panel-metamask{
    width: 73.62%;
    left: 22.32%;
    top: 57.53%;
    font-size: min(1.85vh, 1.04vw); /*20px*/
}

.vesting-panel-total .vesting-panel-footer{
    height: 23.44%;
    top: 72.85%;
    font-size: min(1.39vh, .78vw); /*15px*/
    color: #22A7D1;
    opacity: 0.6;
}

.vesting-panel-claimed .vesting-panel-sum{
    top: 22.92%;
}

.vesting-panel-claimed .vesting-panel-percents{
    width: 23.62%;
    height: 14.57%;
    left: 74.41%;
    top: 68.11%;
    font-size: min(1.39vh, .78vw); /*15px*/
    text-shadow: none;
}

.vesting-panel-bar {
    position: absolute;
    width: 62.99%;
    height: 19.44%;
    left: 8.27%;
    top: 65.97%;

    font-size: min(1.81vh, 1.02vw);/*19.5px*/
    background: #272B4D;
    box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.61);
    border-radius: 1em;
}

.vesting-panel-fill {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    transition: all 1.5s;

    font-size: min(1.81vh, 1.02vw);/*19.5px*/
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.61);
    border-radius: 1em;
}

.vesting-panel-button {
    position: absolute;
    width: 79.92%;
    height: 32.64%;
    left: 10.24%;
    top: 50%;

    background: #272B4D;
    box-shadow: inset 0px 4px 7px rgba(0, 0, 0, 0.61);
    border-radius: 23.5px;
}

.vesting-panel-button:hover {
    background: #DFE0EB;
}

.vesting-panel-button-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: min(1.39vh, .78vw); /*15px*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vesting-icon {
    position: absolute;
}

.vesting-icon-wallet {
    width: 16.52%;
    height: 11.96%;
    left: 20.58%;
    top: 42.47%;
}

.vesting-icon-metamask {
    width: 15.07%;
    height: 9.9%;
    left: 21.16%;
    top: 57.32%;
}

.vesting-texts {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    transition: all .5s;
    pointer-events: none;
    z-index: 4;
    opacity: 0;
}

.vesting-texts.vesting-texts-show {
    opacity: 1;
    pointer-events: all;
}

.vesting-text {
    position: absolute;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: min(1.39vh, .78vw); /*15px*/
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.vesting-text.vesting-text-header {
    width: 100.05%;
    height: 7.5%;
    left: 0.1%;
    top: 51.39%;

    font-weight: 500;
    font-size: min(5.09vh, 2.86vw); /*55px*/
    text-align: center;
    justify-content: center;
    letter-spacing: 0.15em;

    color: #73B6F3;
}

.vesting-text.vesting-text-par1 {
    width: 23.59%;
    height: 10.56%;
    left: 38.75%;
    top: 55.46%;
}

.vesting-text.vesting-text-par2 {
    width: 23.23%;
    height: 9.81%;
    left: 38.75%;
    top: 61.85%;
}

.vesting-text.vesting-text-par3 {
    width: 23.02%;
    height: 14.17%;
    left: 38.75%;
    top: 64.91%;
}

.vesting-text.vesting-text-par4 {
    width: 22.5%;
    height: 10.56%;
    left: 64.06%;
    top: 49.44%;
}

.vesting-text.vesting-text-par5 {
    width: 22.5%;
    height: 8.8%;
    left: 64.06%;
    top: 54.81%;
}

.vesting-text.vesting-text-par6 {
    width: 22.5%;
    height: 8.43%;
    left: 64.06%;
    top: 59.72%;
}

.vesting-text.vesting-text-par7 {
    width: 23.39%;
    height: 8.33%;
    left: 64.06%;
    top: 66.2%;
    color: #73B6F3;
}
