.wrt-animation {
    display: block;
    position: absolute;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    visibility: visible;
    opacity: 1;
    transition: opacity .5s;
}
